<template>
  <div class="usa-accordion usa-accordion--bordered">
    <div class="usa-space">
    <!-- Shubham,5-feb-2024,Replaced h4 from h3-->
      <h3 class="usa-accordion__heading" @click="toggle()">
        <button
          type="button"
          class="usa-accordion__button card-button-content"
          :aria-expanded="showSection ? 'true' : 'false'"
          :aria-controls="data.fields.sectionHeading"
          :class="{ active: showSection }"
          :aria-label="showlabel"
        >
          {{ data.fields.sectionHeading }}
          <span class="arrow-right">
            <img :src="showSection ? '/assets/img/minus.svg': '/assets/img/plus.svg'" alt="Expand All" width="20">
          </span>
        </button>
      </h3>
    
      <div v-if = "!isCustomizePage"  class="usa-accordion__content" :class="{ active: showSection }" :id="data.fields.sectionHeading">
        <rich-text-renderer
          :data="data.fields.sectionBody"
        ></rich-text-renderer>
       </div>
      <div   v-if = "isCustomizePage" v-html ="data.fields.sectionHtml" class="usa-accordion__content" :class="{ active: showSection }" :id="data.fields.sectionHeading">
      </div>
      </div>
    </div>
  </div>
</template>
<script>
import { LABEL_FOR_LANGING_PG,LABEL_FOR_STEP_5 } from "@/config";
import { urlSlug } from "@/helpers/url";
export default {
  name: "accordion-component",
  props: ["data", "additionaldata"],
  data() {
    return {
      showSection: false,
    };
  },
  computed: {
    encapsulator() {
      return this.additionaldata && this.additionaldata.encapsulator;
    },
    showlabel() {
      const urlSlugText = urlSlug();
      if (urlSlugText == "") return LABEL_FOR_LANGING_PG + this.data.fields.sectionHeading;
      if (urlSlugText == "step-5") return LABEL_FOR_STEP_5 + this.data.fields.sectionHeading;
    },
  },

  methods: {
    toggle() {
      this.showSection = !this.showSection;
    },
  },
  created() {
        // console.log(this.$route.path);
         if(this.$route.path == '/') {
       this.isCustomizePage = true;
     } else {
       this.isCustomizePage = false;
     }
    this.encapsulator &&
      this.encapsulator.$on("expand-all", () => {
        this.showSection = true;
      });
    this.encapsulator &&
      this.encapsulator.$on("collapse-all", () => {
        this.showSection = false;
      });
  },
};
</script>

<style lang="scss" scoped>
.plus {
  display: block;
}
.usa-accordion__button.active {
  //display: block;
  //background-image: url(/assets/img/minus.svg) !important;
  background-image: none !important;
}
.minus {
  display: none;
}
// .usa-accordion__button  {
//   //display: none;
//     background-image: url(/assets/img/plus.svg)!important;

// }
.usa-accordion__button[aria-expanded=false] {
  background-image: none !important;
}
.usa-space {
  margin-bottom: 11px;
}
.usa-accordion__content.active {
  display: block;
}
.usa-accordion__content {
  display: none;
  text-align: left;
  ::v-deep p {
    margin: 0 0 10px;
    padding: 0;
    &:last-child {
      margin: 0;
    }
  }
}
.usa-accordion__heading, .usa-prose .usa-accordion__heading {
  font-size: 1.06471rem !important;
}
.usa-accordion__button{
    padding-right: 33px;
}
.arrow-right {
  float: right;
}
</style>