<template>
  <div
    class="grid-col-12 margin-top-4 grid-gap child-block"
    :class="[parentClass, data.fields.styleInstructions, getFullWidth]"
    :id="data.fields.cardSlug"
  >
    <!-- Shubham,5-feb-2024,Added unique name for each region-->
    <section
      class="cgi-card bg-white display-flex flex-column padding-3 shadow-2" :aria-labelledby="'more-resources-header-' + data.fields.cardSlug">
    <!-- Shubham,5-feb-2024,Replaced h4 from h3 -->
      <div class="cgi-card__head font-sans-lg text-bold">
        <h3 id="more-resources-header-1">{{ data.fields.cardHeading }}</h3>
      </div>
      <div class="cgi-card__body grid-col-fill padding-y-2">
        <rich-text-renderer :data="data.fields.cardBody"></rich-text-renderer>
      </div>
      <div class="cgi-card__foot" v-if="data.fields.learnMoreUrl">
        <div
          class="usa-link cgi-link-next text-bold block-link"
          v-if="data.fields.learnMoreUrl"
        >
          <rich-text-renderer
            :data="data.fields.learnMoreUrl"
          ></rich-text-renderer>
          <!-- <svg width="24" height="24" viewBox="0 0 192 512">
            <path
              d="M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z"
            ></path>
          </svg> -->
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { forEach } from "lodash";
export default {
  name: "more-block",
  props: ["data", "additionaldata"],
  data() {
    return {
      parentClass: "",
    };
  },
  created() {
    if (this.additionaldata){    
      if (this.additionaldata.instruction == "3-columns") {
        this.parentClass = "tablet:grid-col-4";
      } else {
        this.parentClass = "tablet:grid-col-6";
      }
    } 
  },
  computed: {
    getFullWidth() {
      return this.data.fields.sectionStyleInstruction === "full-width"
        ? "tablet:grid-col-12"
        : "";
    },
  },
};
</script>
<style lang="scss" scoped>
.block-link {
  display: flex;
  align-items: center;
  ::v-deep .usa-link {
    &:after {
      position: absolute;
      background: url(/assets/img/angle-arrow-right-blue.svg) no-repeat;
      width: 9px;
      height: 19px;
      margin-left: 0.5em;
      content: "";
      background-size: 14px 24px;
    }
  }
}
.grid-gap {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.block-link {
  display: flex;
  align-items: center;
}
.cgi-card {
  ::v-deep p {
    font-size: 1rem;
    line-height: 1.4;
  }
}
.non-shaded {
  ::v-deep .shadow-2 {
    box-shadow: none;
  }
}
.all-heading-center {
  text-align: center !important;

  ::v-deep .cgi-card {
    h5 {
      font-size: 1.2rem !important;
      margin: 0 0 5px;
    }
    h4 {
      font-size: 0.99816rem;
    }
    p {
      text-align: left !important;
      margin: 0 !important;
    }
    .cgi-card__body {
      padding: 0;
      h4 {
        margin-bottom: 25px;
      }
    }
  }
}
.small-card {
  .cgi-card {
    padding: 0 1.5em .5em;
    p {
      margin-bottom: 0;
    }
  }
  ::v-deep .cgi-card__body {
    padding: 10px 0;
  }
  ::v-deep .cgi-card__foot {
    padding-bottom: 15px;
    p {
      margin: 0;
    }
  }
}

.travel-coordinator{
  .cgi-card__head {
    h4{
          font-size: 1.46397rem;
    }
  }
  .cgi-card__body{
    padding-top: 0;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .child-block{ 
    padding: 0;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .child-block { 
    padding: 0;
  }
}

</style>