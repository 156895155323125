<template>
<!-- Shubham,5-feb-2024, Added main landmark -->
  <div id="top" :class="[isRTL ? 'rtl-global' : '']" role="main">
    <document-renderer
      v-show="$route.name!=='landing-page' || ''" 
      v-if="langCode && countryCode"
      contentType="headerMenu"
      slug="header"
      :langCode="langCode"
      :countryCode="countryCode"
      :documentId="headerDocumentId"
    ></document-renderer>
    <router-view />
    <back-to-top></back-to-top>
    <document-renderer
      v-show="$route.name!=='landing-page' || ''" 
      v-if="langCode && countryCode"
      contentType="fullFooter"
      slug="footer"
      :langCode="langCode"
      :countryCode="countryCode"
      :documentId="footerDocumentId"
    ></document-renderer>
  </div>
</template>

<script>
import "@/scss/style.scss";
import { HEADER_CONTENT_ID, FOOTER_CONTENT_ID } from "@/config";
import BackToTop from "@/components/BackToTop";
import { urlCountryCode, urlLangCode } from "@/helpers/url";
import { getCountryName, getRTLCountry } from "@/store/api";

export default {
  name: "App",
  components: { BackToTop },
  props: {
    isRTL: Boolean
  },
  data() {
    return {
      headerDocumentId: HEADER_CONTENT_ID,
      footerDocumentId: FOOTER_CONTENT_ID,
      countryCode: this.$route.params.countrycode,
      langCode: this.$route.params.langcode,
    };
  },
  created() {
    this.countryCode = urlCountryCode();
    this.langCode = urlLangCode();
    getCountryName(this.countryCode).then((country) => {
        this.country = country;
    });
    getRTLCountry(this.countryCode, this.langCode).then((country) => {
      if(country) {
        let itemObj = Object.keys(country).map((key) => ({ key, value: country[key] }));
        let finalItemObj = itemObj.filter(item => item.key == this.langCode);
        if(finalItemObj[0].value == 'rtl') {
          this.isRTL = true
        } else {
          this.isRTL = false
        }
      }
    });
  },
  watch: {
    $route(newRoute, oldRoute) {
      this.countryCode = newRoute.params.countrycode;
      this.langCode = newRoute.params.langcode;
    },
  },
};
</script>

<style lang="scss">
  .rtl-global {
    direction: rtl;
  }
</style>
