<template>
  <div class="usa-alert usa-alert--inline-svg usa-alert--no-icon usa-alert--info usa-alert--slim text-left" role="alert" :class="data.fields.noticeType">
    <div class="usa-alert__icon blue" v-if="data.fields.noticeType === 'info-light-blue'">
      <svg width="24" height="24" viewBox="0 0 216 146" role="img" focusable="false" aria-labelledby="svgTitle" aria-describedby="svgDescription">
        <title id="svgTitle">Important Message</title>
        <desc id="svgDescription">Important Message</desc>
        <path d="M162.18 41.592c-5.595-9.586-13.185-17.176-22.771-22.771-9.588-5.595-20.055-8.392-31.408-8.392-11.352 0-21.822 2.797-31.408 8.392-9.587 5.594-17.177 13.184-22.772 22.771-5.596 9.587-8.393 20.057-8.393 31.408 0 11.352 2.798 21.82 8.392 31.408 5.595 9.585 13.185 17.176 22.772 22.771 9.587 5.595 20.056 8.392 31.408 8.392s21.822-2.797 31.408-8.392c9.586-5.594 17.176-13.185 22.771-22.771 5.594-9.587 8.391-20.057 8.391-31.408 0-11.352-2.797-21.822-8.39-31.408zM97.572 26.071c0-.761.244-1.385.733-1.874.489-.488 1.114-.733 1.874-.733h15.644c.76 0 1.385.245 1.872.733.488.489.734 1.113.734 1.874v13.036c0 .76-.246 1.385-.734 1.873-.487.489-1.112.733-1.872.733h-15.644c-.76 0-1.385-.244-1.874-.733-.489-.488-.733-1.113-.733-1.873V26.071zm31.285 86.036c0 .76-.246 1.385-.733 1.872-.487.489-1.112.733-1.874.733h-36.5c-.761 0-1.385-.244-1.874-.733-.489-.488-.733-1.113-.733-1.873V99.07c0-.762.244-1.385.733-1.874.489-.488 1.114-.733 1.874-.733h7.822V70.392H89.75c-.761 0-1.385-.244-1.874-.733-.489-.488-.733-1.113-.733-1.874V54.75c0-.761.244-1.385.733-1.874.489-.489 1.114-.733 1.874-.733h26.073c.76 0 1.385.244 1.872.733.488.489.734 1.113.734 1.874v41.714h7.82c.761 0 1.386.245 1.874.733.487.488.733 1.113.733 1.874v13.036z"></path>
      </svg>
    </div>
    <div class="usa-alert__icon yellow" v-if="data.fields.noticeType === 'caution-yellow'" >
      <svg width="24" height="24" viewBox="0 0 216 146" role="img" focusable="false" aria-labelledby="svgCautionTitle" aria-describedby="svgCautionDescription">
        <title id="svgCautionTitle">Warning Message</title>
        <desc id="svgCautionDescription">Warning Message</desc>
        <path d="M179.695 125.388L117.126 10.673a10.39 10.39 0 0 0-3.832-3.992 10.1 10.1 0 0 0-5.295-1.467c-1.901 0-3.667.49-5.296 1.467s-2.906 2.308-3.829 3.992L36.303 125.388c-1.901 3.423-1.847 6.845.163 10.267a10.24 10.24 0 0 0 3.789 3.746 10.188 10.188 0 0 0 5.174 1.387H170.57c1.849 0 3.572-.463 5.175-1.387a10.24 10.24 0 0 0 3.789-3.746c2.01-3.423 2.064-6.844.161-10.267zm-61.265-8.148c0 .76-.259 1.398-.773 1.914-.516.516-1.127.773-1.834.773H100.18c-.706 0-1.317-.257-1.833-.773-.516-.517-.774-1.154-.774-1.914v-15.48c0-.76.258-1.397.774-1.914.516-.516 1.126-.773 1.833-.773h15.642c.707 0 1.318.257 1.834.773.515.517.773 1.154.773 1.914v15.48zm-.162-30.47c-.056.543-.341.991-.856 1.344-.517.354-1.154.529-1.915.529h-15.073c-.76 0-1.412-.176-1.955-.529-.544-.354-.815-.801-.815-1.346l-1.385-37.231c0-.761.272-1.331.815-1.711.706-.597 1.358-.896 1.956-.896h17.924c.598 0 1.25.298 1.956.896.543.38.813.896.813 1.548l-1.465 37.396z"></path>
      </svg>
    </div>
    <div class="usa-alert__icon plain" v-if="data.fields.noticeType === 'plain-text-caution'">
      <svg width="24" height="24" viewBox="0 0 216 146" role="img" focusable="false" aria-labelledby="svgPlainTitle" aria-describedby="svgPlainDescription">
        <title id="svgPlainTitle">alert Message</title>
        <desc id="svgPlainDescription">alert Message</desc>
        <path  d="M179.695 125.388L117.126 10.673a10.39 10.39 0 0 0-3.832-3.992 10.1 10.1 0 0 0-5.295-1.467c-1.901 0-3.667.49-5.296 1.467s-2.906 2.308-3.829 3.992L36.303 125.388c-1.901 3.423-1.847 6.845.163 10.267a10.24 10.24 0 0 0 3.789 3.746 10.188 10.188 0 0 0 5.174 1.387H170.57c1.849 0 3.572-.463 5.175-1.387a10.24 10.24 0 0 0 3.789-3.746c2.01-3.423 2.064-6.844.161-10.267zm-61.265-8.148c0 .76-.259 1.398-.773 1.914-.516.516-1.127.773-1.834.773H100.18c-.706 0-1.317-.257-1.833-.773-.516-.517-.774-1.154-.774-1.914v-15.48c0-.76.258-1.397.774-1.914.516-.516 1.126-.773 1.833-.773h15.642c.707 0 1.318.257 1.834.773.515.517.773 1.154.773 1.914v15.48zm-.162-30.47c-.056.543-.341.991-.856 1.344-.517.354-1.154.529-1.915.529h-15.073c-.76 0-1.412-.176-1.955-.529-.544-.354-.815-.801-.815-1.346l-1.385-37.231c0-.761.272-1.331.815-1.711.706-.597 1.358-.896 1.956-.896h17.924c.598 0 1.25.298 1.956.896.543.38.813.896.813 1.548l-1.465 37.396z"></path>
      </svg>
    </div>
    <div class="usa-alert__icon red" aria-label="alert message"   v-if="data.fields.noticeType === 'red-caution'">
      <svg width="24" height="24" viewBox="0 0 216 146" role="img" focusable="false" aria-labelledby="svgRedTitle" aria-describedby="svgRedDescription">
        <title id="svgRedTitle">alert Message</title>
        <desc id="svgRedDescription">alert Message</desc>        
        <path d="M162.18 41.592c-5.595-9.586-13.185-17.176-22.771-22.771-9.588-5.595-20.055-8.392-31.408-8.392-11.352 0-21.821 2.797-31.408 8.392-9.587 5.594-17.177 13.184-22.772 22.771-5.596 9.587-8.393 20.057-8.393 31.408 0 11.351 2.798 21.82 8.392 31.408 5.595 9.584 13.185 17.176 22.772 22.771 9.587 5.595 20.056 8.392 31.408 8.392s21.822-2.797 31.408-8.392c9.586-5.594 17.176-13.185 22.771-22.771C167.773 94.82 170.57 84.35 170.57 73c0-11.351-2.797-21.822-8.39-31.408zm-43.75 70.433c0 .761-.246 1.398-.734 1.914s-1.086.773-1.793.773H100.26c-.706 0-1.331-.271-1.874-.814-.543-.543-.814-1.168-.814-1.873V96.546c0-.706.271-1.331.814-1.874.543-.543 1.168-.814 1.874-.814h15.643c.707 0 1.306.258 1.793.773.488.518.734 1.154.734 1.915v15.479zm-.164-28.026c-.055.543-.339 1.019-.854 1.426-.517.407-1.154.61-1.914.61h-15.073c-.761 0-1.413-.203-1.956-.61-.543-.407-.815-.883-.815-1.426l-1.385-50.595c0-.653.271-1.141.814-1.467.544-.434 1.196-.652 1.956-.652h17.926c.761 0 1.412.217 1.955.652.543.326.813.815.813 1.467l-1.467 50.595z"></path>
      </svg>
    </div>
    <div class="usa-alert__body">
      <div class="usa-alert__text">
        <b>{{ data.fields.noticeHeading}} </b>
        <rich-text-renderer
        :data="data.fields.noticeDescription"
        ></rich-text-renderer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "notice-component",
  props: ["data"],
created(){
    console.log('hereree')

  console.log('data.fields',this.data.fields)
}
};
</script>

<style lang="scss" scoped>

* {
  font-family: Source_Sans_Pro_Web, Helvetica Neue, Helvetica, Roboto, Arial,
    sans-serif;
  color: #000000;
}
::v-deep .usa-link {
  color: #0071BC !important;
}
::v-deep .usa-link.external-img:visited:hover {
  color: #004370;
  text-decoration: underline;
}
::v-deep .external-img {
    filter: none !important;
}
.usa-alert {
  margin-bottom: 15px;
}
.caution-yellow{
  background-color:#faf3d1;
}
.info-light-blue{
  background-color:#e7f6f8;
}
.caution-yellow:before {
    background-color: #ffbe2e;
}
.info-light-blue:before {
    background-color: #00bde3;
}
.plain-text-caution{
  background-color: transparent!important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.plain-text-caution:before{
  background-color: transparent!important;
}
.red-caution{
  background-color: #f4e3db;
}
.red-caution:before{
  background-color: #d63e04;
}
.usa-alert__icon {
  &.plain {
    svg {
      width: 24px;
      vertical-align: sub;
    }
  }
}
::v-deep .usa-alert__text {
  p {
    margin: 1rem 0 !important;
    display: block !important;
  }
}
</style>