<template>
  <button :class="data.fields.styleInstructions" class="btnCta">
    <rich-text-renderer :data="data.fields.link"></rich-text-renderer>

    <rich-text-renderer v-if="data.fields.buttonActionType === 'JS-CLOSE' || data.fields.buttonActionType === 'JS-PRINT'" :data="data.fields.text">
    </rich-text-renderer>
  </button>
</template>
<script>
export default {
  name: "button-cta",
  props: ["data"],
  created() {
    this.$root.$emit('buttonCta:loaded',this.data.fields.styleInstructions);
  }
};

</script>
<style lang="scss" scoped>
::v-deep .usa-button {
  p {
    margin: 0;
  }
}

.red-white {
  margin-bottom: 5em;
  ::v-deep a {
    background-color: #e52207;
    font-family: Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
    font-size: 1.06471rem;
    line-height: .93923;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    border-radius: .25rem;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    margin-right: .5rem;
    padding: .75rem 1.25rem;
    text-align: center;
    text-decoration: none;
    width: auto;
    text-transform: uppercase;

    span {
      display: none;
    }

    &:hover {
      span {
        display: none;
      }
    }
  }
}

.blue-white {
  ::v-deep a {
    font-family: Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
    font-size: 1.06471rem;
    line-height: .93923;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #005ea2;
    border: 0;
    border-radius: .25rem;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    margin-right: .5rem;
    padding: .75rem 1.25rem;
    text-align: center;
    text-decoration: none;
    width: auto;

    span {
      display: none;
    }

    &:hover {
      color: #FFF;

      span {
        display: none;
      }
    }
  }
}

.close-button,.print-button {
  font-family: Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
  font-size: 1.06471rem;
  line-height: .93923;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #005ea2;
  border: 0;
  border-radius: .25rem;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  margin-right: .5rem;
  padding: .75rem 1.25rem;
  text-align: center;
  text-decoration: none;
  width: auto;
  ::v-deep p {
    margin: 0 !important;
    line-height: .93923 !important;
  }
}
.usa-dialog__footer {
  .rich-text {
    display: flex;
    justify-content: center;
  }
}

.btnCta{
  background-color: transparent;
  border: none;
  box-shadow: none;
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 320px) and (max-width: 767px) {
  .blue-white {
    ::v-deep a {
      width: 100%;
    }
  }
  .step-6{
    .blue-white{
      ::v-deep a {
      width: 70%;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .blue-white {
    ::v-deep a {
      width: 100%;
    }
  }
  .step-6{
    .blue-white{
      ::v-deep a {
      width: 70%;
      }
    }
  }
}
 
@media (min-width: 320px) and (max-width: 480px) {
  .red-white{
    ::v-deep a{
      width: 100%;
      margin-bottom: 10px;
    }
  }
  .close-button {
    width: 100%;
  }
  .print-button{
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>
