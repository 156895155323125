export function urlCountryCode() {
  const pathParts = location.pathname.split('/').filter(v => v);
  return pathParts[0];
}

export function urlLangCode() {
  const pathParts = location.pathname.split('/').filter(v => v);
  return pathParts[1];
}

export function urlSlug() {
  const pathParts = location.pathname.split('/').filter(v => v);
  // console.log("path"+location.pathname+"length:"+ pathParts.length);
  // console.log(pathParts);
  if (pathParts.length === 3) {
    return pathParts[2];
  }
  return '';
}

