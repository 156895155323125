import { render, staticRenderFns } from "./VisaTypeBlock.vue?vue&type=template&id=6ed948a8&scoped=true&v-if=data%20%26%26%20data.fields&"
import script from "./VisaTypeBlock.vue?vue&type=script&lang=js&"
export * from "./VisaTypeBlock.vue?vue&type=script&lang=js&"
import style0 from "./VisaTypeBlock.vue?vue&type=style&index=0&id=6ed948a8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ed948a8",
  null
  
)

export default component.exports