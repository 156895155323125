<template>
  <div
    class="visa-step-wrapper"
    @mouseover="mouseover = true"
    @mouseout="mouseover = false"
    :class="[showBigIcon]"
  >
  <!-- Shubham, 9-feb-2024, Added aria current so the screen reader can announce the current selected step  -->
    <a
      :href="
        '/' +
        $route.params.countrycode +
        '/' +
        $route.params.langcode +
        '/' +
        data.fields.slug
      "
      :class="{ active: isActive }"
      :aria-current="isActive ? 'page' : 'false'"
    >
      <step-icon
        :icon="data.fields.svgIconCode"
        :hover="mouseover"
        :active="isActive"
      ></step-icon>
      <span class="grid-col-auto tablet:grid-col-12 cgi-step__label margin-05 text-base-darkest" v-if="showBigIcon">
        {{translationKeywords['step'] || translationKeywords['step-'+ data.fields.sequence] || 'Step'}} <span v-if ="translationKeywords['step']">{{ data.fields.sequence }}</span>
      </span>
      <span class="heading">{{ data.fields.heading }}</span>
    </a>
    <span class="progress"></span>
  </div>
</template>

<script>
import { urlSlug, urlLangCode } from "@/helpers/url";
import { getTranslationKeywords } from "@/store/api";

function isActive() {
  return urlSlug() === this.data.fields.slug;
}

function to() {}

export default {
  name: "visa-step",
  props: ["data"],
  computed: { 
    isActive,
    to,
    showBigIcon(){
      const urlSlugText =  urlSlug();
      if(urlSlugText == 'nonimmigrant-visa') return this.data.fields.styleInstructions;
      else return "";
    }
  },
  data() {
    return {
      mouseover: false,
      translationKeywords:{}
    };
  },
  beforeCreate() {
    getTranslationKeywords(urlLangCode()).then(
      (keywords) => (this.translationKeywords=keywords)
    );
  }
};
</script>

<style lang="scss" scoped>
.visa-step-wrapper {
  position: relative;
  max-width: 200px;
  a {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    text-decoration: none;
    padding: 1em;
    position: relative;
    z-index: 100;
    &.active {
      color: black;
    }

    &:hover {
      text-decoration: underline;
      color: #0e4370;
    }

    span.heading {
      font-size: 1rem;
      line-height: 1.3;
      text-decoration: none;
      margin: 0.8rem;
    }
  }

  span.progress {
    width: 110%;
    height: 1px;
    display: block;
    background-color: #a9aeb1;
    margin-top: 20px;
    position: absolute;
    top: 15px;
    left: 48%;
    z-index: 50;
  }
  &.large-icon {
    margin-bottom: 30px;
    span.progress {
      top: 35px;
    }
    ::v-deep .icon {
      width: 80px;
      height: 80px;
      padding: 20px;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}
/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 320px) and (max-width: 767px) {
  .visa-step-wrapper {
    max-width: 100%;
    font-family: Helvetica, Helvetica Neue, Helvetica, Roboto, Arial,
        sans-serif !important;
    a {
      flex-direction: initial;
      padding: 2.2rem 1rem 2.2rem 0;
      span.heading {
        margin: 0.5rem;
        text-align: left;
      }
    }
    span.progress {
      top: 33px;
      left: 22px;
      height: 122px;
      width: 1px;
    }
    &.large-icon {
        font-family: Helvetica, Helvetica Neue, Helvetica, Roboto, Arial,
        sans-serif !important;
      span.progress {
        top: 15px;
        left: 50px;
        height: 165px;
        width: 1px;
      }
      ::v-deep .icon {
        width: 65px;
        height: 65px;
        padding: 15px;
        margin-right: 10px;
      }
    }
  }

  .nonimmigrant-visa {
      font-family: Helvetica, Helvetica Neue, Helvetica, Roboto, Arial,
        sans-serif !important;
    .visa-step-wrapper {
      ::v-deep a {
        flex-direction: initial;
        padding: 1em;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .visa-step-wrapper {
    a {
      flex-direction: column;
    }
  }
}
</style>