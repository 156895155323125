<template>
    <!-- Shubham, 8-feb-2024, Resolve the parsing error were this.route was going null. To manage that made a function to check the route's availability -->
  <div v-if = "isRouteNameAvailable && !this.$route.name.includes('depositslip') && !this.$route.name.includes('landing')" class="row footer-container centered clearfix">
    <div class="container footer-navigation mobile-reorder" :class="showSubMenu">
      <rich-text-renderer
        :data="data.fields.footerBody"></rich-text-renderer>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import { urlSlug } from "@/helpers/url";

export default {
  name: "footer-with-links",
  props: ["data"],
  components: {
    Footer
  },
  computed: {
    showSubMenu(){
    const urlSlugText =  urlSlug();
    if(urlSlugText == '') return "none";
      else return "";
    },
    // Shubham, 8-feb-2024, Added function to check the route's availability
    isRouteNameAvailable(){
    if(this.$route && this.$route.name) return true;
      else return false;
    }
  }
};
</script>

<style scoped lang="scss">
.row {
  background-color: #373737;
}

.container {
  padding-top: 2rem;
  padding-bottom: 4rem;
}

.rich-text {
  display: flex;
  justify-content: space-between;
}
</style>

<style scoped lang="scss">
.rich-text {
  ::v-deep {
    h3 {
      color: #FFF;
      margin: 15px 0;
      font-size: 1.3rem;
    }
    a,
    h1 {
      color: white;
      text-decoration: none;
    }
    a:hover {
      text-decoration: underline;
      color: #ffffff;
    }
    .footer-heading {
      color: white;
      font-size: 22px;
      margin-bottom: 0;
    }
  }
}

::v-deep {
  h1,
  h4 {
    margin: 15px 0;
  }
  h4 {
    font-size: 1.06471rem;
  }
  .rich-text p {
    margin: 0;
    line-height: 1.4;
    font-size: 1.06471rem;
  }
}
.none {
  display: none;
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (max-width: 480px) {
  .footer-container {
    width: 100%;
    .container {
      padding-bottom: 2rem;
      .rich-text {
        flex-wrap: wrap;
        width: 100%;
        ::v-deep div {
          width: 100%;
          &:nth-child(1) {
            order: 1;
          }
          &:nth-child(2) {
            order: 3;
          }
          &:nth-child(3) {
            order: 2;
          }
        }
      }
    }
  }
}

/* Responsive Mobile Sizes */
@media only screen and (max-width: 480px) {
  .centered {
    .footer-navigation {
      padding: 0;
      ::v-deep .footer-links-holder {
        width: 100%;
        padding: 0;

        h3 {
          margin: 0;
          padding: 10px 20px;
          border-top: #000 1px solid;
          cursor: pointer;

          /* Down Arrows */
          &::before {
            font-weight: bold;
            margin-right: 10px;
            content: "";
            display: inline-block;
            vertical-align: middle;
            margin-top: -6px;
            width: 8px;
            height: 8px;
            border-top: 2px solid #fff;
            border-right: 2px solid #fff;
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            -webkit-transition: transform 0.5s;
            transition: transform 0.5s;
          }
        }

        .footer-links {
          max-height: 0;
          overflow: hidden;
          padding: 0 10px 0 30px;
        }

        &.active {
          h3 {
            &::before {
              -moz-transform: rotate(135deg);
              -ms-transform: rotate(135deg);
              -webkit-transform: rotate(135deg);
              transform: rotate(135deg);
            }
          }

          .footer-links {
            max-height: 100%;
            padding-bottom: 10px;
          }
        }
      }
    }
  }
  .mobile-reorder {
    display: flex;
    flex-direction: column;
  }
}

/* Misc CSS */
.clearfix {
  &:before,
  &:after {
    content: "\0020";
    display: block;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0;
    clear: both;
  }
}
</style>
