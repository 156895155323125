export default {
  HomePageTemplate: [{
    path: '',
    name: 'home',
  }],
  DepositSlipTemplate: [
  'depositslip',
  'depositslip1',
  'depositslip2',
  'depositslip3',
  'kr-niv-eft160',
  'kr-niv-eft-190',
  'kr-niv-eft-265',
  'kr-niv-eft-205',
  // Avnish, 07-July-2023, Go Landing Page Template routing added.
  "landing"
 ],
  StepPageTemplate: [
    'step-1', 'step-2', 'step-3', 'step-4', 'step-5', 'step-6',
    'contact-us', 'general-information','PKM-SPT','MD-SPT','SK-SPT','landing-page'
  ],
  PageTemplateLeftMenuAndBody: [
    'terms-and-conditions',
    'business-visa',
    'work-visa',
    'student-visa',
    'exchange-visitor-visa',
    'transit-ship-crew-visa',
    'religious-worker-visa',
    'domestic-employee-visa',
    'journalist-and-media-visa',
    'list-of-niv-visa-types',
    'submitting-your-documents',
    '221g',
    'collection-locations',
    'drop-off-locations',
    'retrieving-your-documents',
    'visa-waiver-program',
    'renew-visa',
    'diplomatic-and-government-officials',
    'no-fee',
    'payment-locations',
    'payment-options',
    'expedited-appointment',
    'travel-coordinator',
    'group-appointments',
    'interview-waiver',
    'privacy-policy',
    'PKM-PTLM',
    'MD-PTLM',
    'SK-PTLM',
    'treaty-traders-and-treaty-investors-visa',
    'entertainers-visa',
    'cnmi-saipan-visa',
    'us-singapore-free-trade-agreement-professional-visa',
    'cnmi-investor-visa',
    'summer-work-travel-program',
    'australian-professional-specialty',
    'victims-visa', 'group-appointments-tutorial','submitting-your-documents-iv','221g-iv','renewal-h1b-niv-in-us'
  ],
  NonImmigrantVisaHomeTemplate: [
    'nonimmigrant-visa',
    'immigrant-visa',
    'PKM-NIVT',
    'MD-NIVT',
    'SK-NIVT',
    'step-4-temp',
    'american-citizens-services',
    'dummy-acs'
  ]
};