<template>
    <div class="scroll-table" :class="[data.fields.styleInstructions]"> 
      <table class="usa-table">
        <caption>{{ data.fields.captionTitle }}</caption>
        <thead>
         <tr v-if="data.fields.tableHedersRef">
            <th :key="i" v-for="(step, i) of data.fields.tableHedersRef" scope="col">
            <document-renderer :documentId="step.sys.id"></document-renderer>
            </th>
         </tr> 
        </thead>
        <tbody>
          <tr :key="i" v-for="(step, i) of tableData">
            <td :key="j" v-for="(step2, j) of step">
              <document-renderer :documentId="step2.sys.id"></document-renderer>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
</template>

<script> 
export default {
  name: "basic-table",
  props: ["data"],
  data(){
    return {
      tableData : [],
      chunk: '',
      splitedStr: ''
    };
  },
  created() {
     var i,j,temparray,chunk;
    chunk = this.data.fields.noOfColumns;
    let tableData = [];
    for (i=0,j=this.data.fields.tableBodyCellRefs.length; i<j; i+=chunk) {
      tableData.push(this.data.fields.tableBodyCellRefs.slice(i,i+chunk));
    }
    this.tableData = tableData;
    this.splitedStr = this.data.fields.title.split(">");
    this.splitTitle = this.splitedStr[2];

  }
};
</script>
 
<style lang="scss" scoped>
  .usa-table {
    caption {
      line-height: 1.52155;
      font-size: 1.06471rem;
      font-weight: 700;
      font-family: Source Sans Pro Web,Helvetica Neue,Helvetica,Roboto,Arial,sans-serif !important;
    }
  }
  .custom-table-col {
    ::v-deep tbody {
      tr {
        td {
          &:first-child {
            width: 130px;
          }
        }
      }
    }
  }

  .horizontal-custom-table {
    border: 1px solid #888;
    width: 100%;
    ::v-deep tbody {
      tr {
        td {
          border: none;
          padding: 3px 8px;
          border-bottom: 1px solid #888;
          &:nth-child(2) {
            padding-left: 12em;
          }
        }
      }
    }
  }
@media (min-width: 320px) and (max-width: 767px) {
    .scroll-table{
    overflow-x: auto;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .scroll-table{
    overflow-x: auto;
  }
}
</style>
