import Vue from "vue";
import VueRouter from "vue-router";
import routeMapping from "@/config/route-template-mapping";
import {
  getCountryResourceGroup,
  getSystemGlobalResources
} from "@/store/api";

Vue.use(VueRouter);

function getRoutes(mapping) {
  const templates = Object.keys(mapping);
  const routes = [];
  templates.map(template => {
    const paths = mapping[template];
    const mapped = paths.map(path => {
      if (typeof path === "string") {
        // Avnish, 07-July-2023, Go Landing Page Template routing added.
        // console.log('path:' + path);
        if(path === 'landing') {
          return {
            path: `/:countrycode/:langcode/${path}`,
            name: path,
            component: () => import(`@/components/GoliveLandingPage.vue`)
          }
          
        }

       return {
          path: `/:countrycode/:langcode/${path}`,
          name: path,
          component: () => import(`@/templates/${template}.vue`)
        };
        
      }

      return {
        path: `/:countrycode/:langcode/${path.path}`,
        name: path.name,
        component: () => import(`@/templates/${template}.vue`)
      };
    });
    routes.push(...mapped);
  });
  return routes;
}

const routes = [
  ...getRoutes(routeMapping),
  {
    path: "/en/landing-page",
    alias: "/"
  },

  {
    path: "*",
    component: () => import(`@/templates/StepPageTemplate.vue`),
    meta: {
    reload: true,
  },
  }
];

const router = new VueRouter({
  trailingSlash: false,
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function(to) {
    if (to.hash) {
      return {
        selector: to.hash
      };
    }
  }
});

router.beforeEach((to, from, next) => {
  // assist in finding old paths
  // getCountryResourceGroup(to.params?.countrycode).then((res) => {
  //   console.log("to.params?.countrycode::",to.params?.countrycode, res);
  //   // Pooja, 26-jul-23, commented go live page redirection, no need now for staging as sudhasu needed
  //   if ((res?.fields?.resJson['redirect-status']) && ((to.path.toLowerCase().indexOf('go-live-landing-page') === -1))){
  //     next({ path: `/${to.params?.countrycode}/${to.params?.langcode}/go-live-landing-page` });
  //   }
  //   else
     if (to.path === '/'){
      return next()
    }
    
    else if ((to.params.countrycode === 'ru'))  { 
    
      next({ path: `/` })
    }
    
    else if ((to.path === '/kr/en/depositslip1') || (to.path ==='/kr/en/depositslip/') || (to.path ==='/kr/en/kr-niv-eft160') || (to.path ==='/kr/en/kr-niv-eft-190') || (to.path ==='/kr/en/kr-niv-eft-265') || (to.path ==='/kr/en/kr-niv-eft-205') ) { 
      console.log('korea dep slip rediredt - '+ to.path);
      next({ path: `/kr/en/` })
    }
    
      
    else if ((to.path ==='/kr/ko/depositslip1/') || (to.path ==='/kr/ko/depositslip/')  || (to.path ==='/kr/ko/kr-niv-eft160') || (to.path ==='/kr/ko/kr-niv-eft160') || (to.path ==='/kr/ko/kr-niv-eft-190')  || (to.path ==='/kr/ko/kr-niv-eft-265') || (to.path ==='/kr/ko/kr-niv-eft-205')) {
          console.log('korea dep slip rediredt - '+ to.path);
          next({ path: `/kr/ko/` })
        }

    
    else if ((to.params.countrycode === 'jp') &&  (to.params.languagecode === 'jp')) { 
      console.log('Japana language rediredt - '+ to.path);
      const myRe = new RegExp("/([a-z]{2})", "g");
      const myArray = myRe.exec(to.path);
      next({ path: `/jp/ja/` })
    }
    
    else if ((to.params?.countrycode?.length === 2) && (to.params?.langcode?.length === undefined)) { 
      console.log('No language - '+ to.path);
      const myRe = new RegExp("/([a-z]{2})", "g");
      const myArray = myRe.exec(to.path);
      next({ path: `/${myArray[1]}/en/` })
    }
    
    // when countryCode exists, but languageCode is not exactly 2 characters
    else if (to.params?.countrycode?.length === 2 && ((to.params?.langcode?.length !== 2))) { 
      console.log('countryCode exists, but languageCode is not exactly 2 characters - '+ to.path)
      const myRe = new RegExp("/([a-z]{2})", "g");
      const myArray = myRe.exec(to.path);
      console.log(`redirecting to /${myArray[1]}/`);
      next({ path: `/${myArray[1]}/en/` })
      
    } else if ((to.params?.countrycode?.length === 5) ) {
      // old locale syntax
      const myRe = new RegExp("([a-z]{2})_([a-z]{2})", "g");
      const myArray = myRe.exec(to.params?.countrycode);
      if (myArray[2] =='jp')
        myArray[2]="ja";
      console.log(`length=5, redirecting to /${myArray[1]}/${myArray[2]}/`)
      next({ path: `/${myArray[1]}/${myArray[2]}/` })
    } else if ((to.params.countrycode === undefined) ) {
      console.log('in no countrycode, and most likely old syntax with another page - '+ to.path)
      const myRe = new RegExp("/([a-z]{2})", "g");
      const myArray = myRe.exec(to.path);
      console.log(`redirecting to /${myArray[1]}/`)
      next({ path: `/${myArray[1]}/en/` })
    }
    return next();
  // });
});

export default router;
