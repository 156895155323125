<template v-if="data && data.fields">
  <div class="type-block">
    <div class="header-img">
      <document-renderer
        :documentId="data.fields.visaTypeImage.sys.id"
        :documentType="data.fields.visaTypeImage.sys.linkType"
      ></document-renderer>
    </div>
    <div class="type-info">
      <h2 class="type-heading">{{ data.fields.visaTypeHeading }}</h2>
      <h2 class="visa-type" v-if="hasVisaType">
        {{ data.fields.visaCodes }}
      </h2>
      <h2 class="visa-fee">{{ data.fields.visaFeeText }}</h2>
    </div>
    <div class="type-description cgi-card__body grid-col-fill">
      <rich-text-renderer
        :data="data.fields.visaTypeDescription"
      ></rich-text-renderer>
    </div>

    <div class="cgi-card__foot padding-2">
      <div
        class="usa-link cgi-link-next text-bold block-link"
        v-if="data.fields.readMoreLink"
      >
        <rich-text-renderer
          :data="data.fields.readMoreLink"
        ></rich-text-renderer>
      </div>
    </div>
  </div>
</template>

<script>
function hasVisaType() {
  return Boolean(this.data.fields.visaCodes);
}

export default {
  name: "visa-type-block",
  props: ["data"],
  computed: { hasVisaType },
};
</script>
<style lang="scss" scoped>
.block-link {
  display: flex;
  align-items: center;
  ::v-deep .usa-link {
    &:after {
      position: absolute;
      background: url(/assets/img/angle-arrow-right-blue.svg) no-repeat;
      width: 9px;
      height: 19px;
      margin-left: 0.5em;
      content: "";
      background-size: 14px 24px;
    }
  }
}
.type-block {
  padding: 0;
  // width: 310px;
  width: 32%;
  border: 2px solid #f0f0f0;
  display: flex;
  flex-direction: column;
  margin: 0 0.3rem 1rem;
  .type-info {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &:last-child {
      height: 100%;
    }
    .type-heading {
      font-size: 1.46397rem;
      text-align: left;
      margin: 0;
    }
    .visa-type,
    .visa-fee {
      color: #71767a;
      font-size: 1.06471rem;
      line-height: 1.52155;
      font-family: Source_Sans_Pro_Web, Helvetica Neue, Helvetica, Roboto, Arial,
        sans-serif;
      margin: 0;
    }
  }

  .type-description {
    height: 100%;
    padding: 0 1rem;
    .rich-text {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    p {
      margin: 0;
    }
  }

  .header-img {
    display: block;
    img {
      width: 100%;
    }
  }
}

::v-deep {
  .cgi-card__foot .cgi-link-next .rich-text p {
    margin: 0;
    text-decoration: none;
  }
}
::v-deep {
  .cgi-card__foot .cgi-link-next .rich-text p span a {
    margin-right: 0px;
  }
}
::v-deep .first-section {
  .container {
    .rich-text {
      p {
        margin: 0 !important;
      }
    }
  }
}


@media (min-width: 320px) and (max-width: 640px) {
  .type-block{ 
    width: 100%;
  }
}
@media (min-width: 641px) and (max-width: 768px) {
  .type-block{ 
    width: 48%;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .type-block{ 
    width: 32% !important;
  }
}
</style>