<template>
  <div
    class="cgi-inline-notice text-white"
    style="background-color: #205493; margin-top: 1px"
    :class="data.fields.styleInstructions"
  >
    <div class="grid-container">
      <div class="grid-row padding-y-2 flex-align-center">
        <div
          class="grid-col-12 mobile-lg:grid-col-auto"
          style="text-align: center"
        >
          <svg role="img" focusable="false" width="24" height="24" viewBox="0 0 216 146"  aria-labelledby="svgTitle" aria-describedby="svgDescription">
            <title id="svgTitle">Important Message</title>
            <desc id="svgDescription">Important Message</desc>
            <path
              d="M162.18 41.592c-5.595-9.586-13.185-17.176-22.771-22.771-9.588-5.595-20.055-8.392-31.408-8.392-11.352 0-21.821 2.797-31.408 8.392-9.587 5.594-17.177 13.184-22.772 22.771-5.596 9.587-8.393 20.057-8.393 31.408 0 11.351 2.798 21.82 8.392 31.408 5.595 9.584 13.185 17.176 22.772 22.771 9.587 5.595 20.056 8.392 31.408 8.392s21.822-2.797 31.408-8.392c9.586-5.594 17.176-13.185 22.771-22.771C167.773 94.82 170.57 84.35 170.57 73c0-11.351-2.797-21.822-8.39-31.408zm-43.75 70.433c0 .761-.246 1.398-.734 1.914s-1.086.773-1.793.773H100.26c-.706 0-1.331-.271-1.874-.814-.543-.543-.814-1.168-.814-1.873V96.546c0-.706.271-1.331.814-1.874.543-.543 1.168-.814 1.874-.814h15.643c.707 0 1.306.258 1.793.773.488.518.734 1.154.734 1.915v15.479zm-.164-28.026c-.055.543-.339 1.019-.854 1.426-.517.407-1.154.61-1.914.61h-15.073c-.761 0-1.413-.203-1.956-.61-.543-.407-.815-.883-.815-1.426l-1.385-50.595c0-.653.271-1.141.814-1.467.544-.434 1.196-.652 1.956-.652h17.926c.761 0 1.412.217 1.955.652.543.326.813.815.813 1.467l-1.467 50.595z"
            ></path>
          </svg>
        </div>
        <div
          class="grid-col-12 mobile-lg:grid-col-fill font-sans-2xs padding-x-2"
          role="alert"
        >
          <rich-text-renderer
            :data="data.fields.noticeBody"
          ></rich-text-renderer>
        </div>
        <div class="grid-col-12 mobile-lg:grid-col-auto padding-2">
          <button
            type="button"
            @click="showModalclick($event)"
            class="usa-button usa-button--big font-sans-sm usa-dialog__open"
            aria-controls="dialog-1"
            :aria-expanded="readMoreExpanded"
            aria-haspopup="true"
            id="imp-notice"
          >
            {{ translationKeywords["read-more"] || "Read more..." }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { urlSlug, urlLangCode } from "@/helpers/url";
import { getTranslationKeywords } from "@/store/api";

export default {
  name: "full-width-notice",
  data: () => ({
    translationKeywords: {},
    readMoreExpanded: "false",
  }),
  props: ["data"],
  methods: {
    showModalclick(ev) {
      this.$root.$emit("modal-popup:show");
      setTimeout(() => {
        this.readMoreExpanded = "true";
        document.getElementById('icon-close-modal').focus()
      }, 0);
    },
  },
  created() {
    this.$root.$on("modal-popup:hide", () => {
      this.readMoreExpanded = "false";
      document.getElementById('imp-notice').focus()
    });
  },
  beforeCreate() {
    getTranslationKeywords(urlLangCode()).then(
      (keywords) => (this.translationKeywords = keywords)
    );
  },
};
</script>

<style lang="scss" scoped>
.immigrant-visa {
  .cgi-inline-notice {
    padding-bottom: 40px;
  }
}
</style>